import React from "react"
import BasicPage from "../components/PageWrapper/BasicPage"

const Presseartikel = (props: any) => {
  return (
    <>
      <BasicPage
        title="Presseartikel"
        location={props.location}
        noindex={true}
        heroSmall={true}
      >
        <div className="flex flex-col justify-center min-h-64 w-full mb-64">
          <a
            href="/handelsblatt_10-2021.pdf"
            className="block"
            target="_blank"
            rel="noreferrer"
          >
            <div className="shadow-md rounded mx-auto my-10 p-6 max-w-xl">
              <div className="text-center font-bold">
                "So können Elektroautofahrer mit ihrem Auto Geld verdienen" vom
                4.10.2021
              </div>

              {/* <HandelsblattLogo className="max-h-12 min-h-12 w-full" /> */}
            </div>{" "}
          </a>
        </div>
      </BasicPage>
    </>
  )
}

export default Presseartikel
